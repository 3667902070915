<template>
  <section></section>
</template>

<script>
export default {
  name: 'Authcallback',
  components: {},
  mounted() {
    let query = this.$route.query;
    let message = {
      type: 'connection_done',
      ...query
    }
    if (window && window.opener) {
      window.opener.postMessage(message, "*");
    }
    // console.log('close the modal');
    window.close();
  },
  methods: {},
};
</script>
<style lang="scss"></style>
