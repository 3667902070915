import nl2br from 'nl2br';

function inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

function scrollToBottom () {
  // console.log('scrollToBottom')
  const messagesContainer = document.querySelector(".Chat-content")
  let haut = 0;
  if (document.querySelector(".QuickReplies")) {
    haut = document.querySelector(".QuickReplies").offsetHeight
  }
  if(messagesContainer) {
    messagesContainer.scrollTop = messagesContainer.scrollHeight + haut
  }
}


function renderAllNewLineTypes(str) {
  str = nl2br(str)
  // When adding several empty lines on dashboard, message is filled with empty paragraphs that are not rendered as
  // we would like to in conversation, so replace empty paragraphs by 'br' tags
  return str.replace(/<p><\/p>/g, '<br/>')
}


export {
  inIframe,
  scrollToBottom,
  renderAllNewLineTypes
}
