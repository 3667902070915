import {
  getTextMessageData,
  getImageMessageData,
  getVideoMessageData,
  getCarouselMessageData,
  getFileMessageData,
  getAdaptiveCardsMessageData,
} from './messageDataFormaters';

function getMessageData(appID, messageObj) {
  // console.log('getMessageData', appID, messageObj);
  let errorMsg;
  switch (messageObj.type) {
    case 'text': {
      if (messageObj.text !== null) {
        let text = messageObj.text;
        if (messageObj.html) text = messageObj.html;
        return { status: true, data: getTextMessageData(appID, text) };
      } else {
        errorMsg = 'text field not present in messageObj';
        break;
      }
    }

    case 'image': {
      if (messageObj.url != null) {
        const { url, fileName } = messageObj;
        return {
          status: true,
          data: getImageMessageData(appID, url, fileName),
        };
      } else {
        errorMsg = 'url field not present in messageObj';
        break;
      }
    }

    case 'video': {
      if (messageObj.url != null) {
        const { url, fileName } = messageObj;
        return {
          status: true,
          data: getVideoMessageData(appID, url, fileName),
        };
      } else {
        errorMsg = 'url field not present in messageObj';
        break;
      }
    }

    case 'file': {
      if (messageObj.url != null) {
        const { url, fileName } = messageObj;
        return { status: true, data: getFileMessageData(appID, url, fileName) };
      } else {
        errorMsg = 'url field not present in messageObj';
        break;
      }
    }

    case 'adaptivecards': {
      if (messageObj.adaptiveCards != null) {
        return { status: true, data: getAdaptiveCardsMessageData(appID, messageObj) };
      } else {
        errorMsg = 'adaptiveCards field not present in messageObj';
        break;
      }
    }

    case 'carousel': {
      if (messageObj.carousel != null) {
        return {
          status: true,
          data: getCarouselMessageData(appID, messageObj),
        };
      } else {
        errorMsg = 'carousel field not present in messageObj';
        break;
      }
    }
    default: {
      errorMsg = 'Unsupported message type';
    }
  }

  return {
    status: false,
    error: errorMsg,
  };
}

function getQuickReplies(questionObj) {
  var quick_replies = [];
  // console.log(questionObj.answers);
  questionObj.answers.forEach(function (answer) {
    // console.log(answer);
    if (answer.answerType && answer.answerType === 'location') {
      quick_replies.push({
        content_type: 'location',
        buttonType: answer.buttonType,
      });
    } else if (answer.answerType && answer.answerType === 'link') {
      // use only by safran Sky Maker
      quick_replies.push({
        content_type: 'web_url',
        title: answer.value.toString(),
        link: answer.link,
        payload: answer.payload,
        buttonType: answer.buttonType,
      });
    } else if (
      answer.answerType &&
      (answer.answerType === 'url' || answer.answerType === 'download')
    ) {
      // use only by safran Sky Maker
      quick_replies.push({
        content_type: 'web_url',
        title: answer.value.toString(),
        url: answer.link,
        payload: answer.payload,
        buttonType: answer.buttonType,
      });
    } else if (
      answer.answerType &&
      (answer.answerType === 'web_url')
    ) {
      // New format for web_url
      quick_replies.push({
        content_type: 'web_url',
        title: answer.value.toString(),
        url: answer.url,
        payload: answer.payload,
        buttonType: answer.buttonType,
      });
    } else if (
      answer.answerType &&
      (answer.answerType === 'login_sso')
    ) {
      // New format for web_url
      quick_replies.push({
        content_type: 'login_sso',
        title: answer.value.toString(),
        url: answer.url,
        payload: answer.payload,
        buttonType: answer.buttonType,
      });
    }
    else if (answer && answer.value) {
      quick_replies.push({
        content_type: 'text',
        title: answer.value.toString(),
        payload: answer.payload,
        buttonType: answer.buttonType,
      });
    }
  });
  return quick_replies;
}

async function handleTypingMessage(message, commit) {
  // console.log('Typing !!!', message.delay)
  if (message.delay != null) {
    commit('SET_TYPING_ON');
    // 'awaiting' setTimeout to finish requires to 'promisify' it
    await new Promise((resolve) =>
      setTimeout(function () {
        commit('SET_TYPING_OFF');
        resolve();
      }, message.delay)
    );
  } else {
    console.error('"typing" message received but delay is not defined.');
  }
}

async function displayFirstResourceContent(
  responseData,
  state,
  dispatch,
  commit
) {
  const resource = responseData.firstMessage.currentResource;
  // console.log(resource);
  // appInfo is useful only for the very first message
  let isVeryFirstMessage = true;
  for (let message of resource.messages) {
    // console.log('for message:', message);
    if (message.type != 'typing') {
      const messageData = getMessageData(state.appID, message);
      // const payload = {appID: resource.appID, text: resource.messages[0].text}
      if (messageData.status) {
        if (isVeryFirstMessage) {
          messageData.data.appInfo = {
            show_widget_notification_delay:
              responseData.application.design.show_widget_notification_delay,
            localStorageDisabled:
              responseData.application.design.localStorageDisabled,
          };
        }
        await dispatch('socket_message', messageData.data);
      } else {
        console.error(`Error while getting message data: ${messageData.error}`);
      }
    } else {
      await handleTypingMessage(message, commit);
    }

    // Messages following the very first message are not the "very first message" :),
    isVeryFirstMessage = false;
  }
  if (resource.answers && resource.answers.length > 0) {
    const quickRepliesData = getQuickReplies(resource);
    await dispatch('socket_message', {
      appID: state.appID,
      quick_replies: quickRepliesData,
    });
  }
}

export {
  getMessageData,
  getQuickReplies,
  handleTypingMessage,
  displayFirstResourceContent,
};
