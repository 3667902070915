import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'
// import app from './modules/app'

import createPersistedState from 'vuex-persistedstate'
import {defaultState, localStorageKey} from './constants'
import {lsTest} from "./utils";

Vue.use(Vuex);

let isStorageOK = false;

if(lsTest() === true){
    // available
    isStorageOK = true;
}else{
    // unavailable
}

let store;
if (isStorageOK) {
  store = new Vuex.Store({
    plugins: [
      createPersistedState({
        key: localStorageKey,
        setState: function(key, state, storage) {
          // console.log(">>>> setState", state?.application?.design?.localStorageDisabled);
          if (state?.application?.design?.localStorageDisabled) {
            // If local storage is disabled at app level, never store anything to it.
            // console.log("Local storage is not allowed");
            return
          }
          // console.log('setState')
          const storedDataJSON = storage.getItem(key);
          const storedDataObj = storedDataJSON ? JSON.parse(storedDataJSON) : {};
          let appDataList = storedDataObj.appDataList;
          const stateCopy = JSON.parse(JSON.stringify(state));
          if (appDataList) {
            let appIdx = appDataList.findIndex(
              ad => (ad.appID === state.appID || ad.urlAppID === state.urlAppID) && ad.uid === state.uid
            );
            if (appIdx === -1 && !state.uid) {
              appIdx = appDataList.findIndex(
                ad => (ad.appID === state.appID || ad.urlAppID === state.urlAppID)
              );
            }
            if (appIdx >= 0) {
              // console.log('OK, This app already exists in local storage!');
              appDataList[appIdx] = stateCopy;
            }
            else {
              console.log('This app DOES NOT exist in local storage with this uid');
              // console.log('Lets check for the uid if it exists')
              if (state.uid) {
                // !ad.uid
                // console.log('STATE UID = ', state.uid)
                let appIdy = appDataList.findIndex(
                  ad => (ad.appID === state.appID || ad.urlAppID === state.urlAppID) && ad.uid === undefined
                );
                if (appIdy >= 0) {
                  stateCopy.respondentID = state.uid;
                  stateCopy.uid = state.uid;
                  appDataList[appIdy] = stateCopy;
                } else {
                  console.log('This app DOES NOT exist in local storage with this respondentID, so we add it');
                  appDataList.push(stateCopy)
                }
              } else {
                console.log('There is not state.uid ')
                // console.log(stateCopy)
                // I dont want to use the "default respondentID"
                // see  Web dans le même navigateur qu'un autre bot (donc respondentID existant dans le store)
                // in issue https://github.com/vizirco/dashboard/issues/3407
                stateCopy.respondentID = undefined
                console.log('This appID DOES NOT exist in the local storage, so we add it');
                appDataList.push(stateCopy)
              }
            }
          }
          else {
            // This should never happen as appDataList is initialized by "LOAD_STATE"
            console.log('appDataList is empty ; init it with current app');
            appDataList = [stateCopy]
          }
          storedDataObj.appDataList = appDataList;
          // if (state.respondentID) {
            // Update "root" respondentID if previously undefined
            // if (!storedDataObj.respondentID) {
              // console.log(`Set "root" respondentID ${state.respondentID}`);
              // I dont want to use the "default respondentID" if the appId is different
              // see  Web dans le même navigateur qu'un autre bot (donc respondentID existant dans le store)
              // in issue https://github.com/vizirco/dashboard/issues/3407
              // @laurent est ce que ça te parait logique de supprimer ce "root" respondent ?
              // storedDataObj.respondentID = state.respondentID
            // }
          // }
          return storage.setItem(key, JSON.stringify(storedDataObj))
        },
        // getState: function(key, storage, value) {
        getState:function(){
          // This is made useless as proper state can only be loaded when appID is known...
          // ...(see 'mutations.js', 'LOAD_STATE')
          return undefined
        }
      })
    ],
    strict: true,  // process.env.NODE_ENV !== 'production',
    state: defaultState,
    actions,
    getters,
    mutations
  })
}
else {
  store = new Vuex.Store({
    strict: true,  // process.env.NODE_ENV !== 'production',
    defaultState: defaultState,
    actions,
    getters,
    mutations
  })
}


export default store
