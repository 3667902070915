
function getAdaptiveCardsMessageData(appID, message) {
  return {
    appID: appID,
    attachment: {
      type: 'adaptivecards',
      payload: message.adaptiveCards,
      messageId: message.messageId,
    }
  }
}

function getTextMessageData(appID, text) {
  return {
    appID: appID,
    text: text
  }
}

function getImageMessageData(appID, url, fileName) {
  return {
    appID: appID,
    attachment: {
      type: "image",
      payload: {
        url: url,
        fileName: fileName
      }
    }
  }
}

function getVideoMessageData(appID, url, fileName) {
  return {
    appID: appID,
    attachment: {
      type: "video",
      payload: {
        url: url,
        fileName: fileName
      }
    }
  }
}

function getFileMessageData(appID, url, fileName) {
  return {
    appID: appID,
    attachment: {
      type: "file",
      payload: {
        url: url,
        fileName: fileName
      }
    }
  }
}

function getAudioMessageData(appID, url) {
  return {
    appID: appID,
    attachment: {
      type: "audio",
      payload: {
        url: url
      }
    }
  }
}

function getCarouselMessageData(appID, message) {
  var carouselItems = message.carousel;
  var elements = [];
  carouselItems.forEach(function(item) {
    if (elements.length>=10) return;
    // console.log(answer);
    var elementObj = {
      title: item.value,
      subtitle: item.textDescription,
      image_url: item.mediaDescription,
      buttons: [],
      imageAspectRatio: item.imageAspectRatio
    }

    if (item.url) elementObj['item_url']=item.url;


    var limit = Math.min(item.answers.length, 3);
    for (var i=0; i<limit; i++) {
      var answer = item.answers[i];
      var answerTitle = (answer.value) ? answer.value.substring(0,20) : "?";

      switch (answer.type) {
        case 'button':
          elementObj.buttons.push({
            type: 'postback',
            title: answerTitle,
            payload: answer.payload || answer.value,
            buttonType: answer.buttonType
          });
          break;
        case 'url':
          elementObj.buttons.push({
            type: 'web_url',
            title: answerTitle,
            url: answer.shortUrl || answer.url || answer.value,
            buttonType: answer.buttonType
            // messenger_extensions: true
          });
          break;
        case 'login_sso':
          elementObj.buttons.push({
            type: 'login_sso',
            title: answerTitle,
            url: answer.shortUrl || answer.url || answer.value,
            buttonType: answer.buttonType
            // messenger_extensions: true
          });
          break;
        case 'phone_number':
          elementObj.buttons.push({
            type: 'phone_number',
            title: answerTitle,
            payload: answer.phone_number || answer.value,
            buttonType: answer.buttonType
          });
          break;
        case 'share':
          if (answer.share_contents) {
            let button = {
              type: 'element_share',
              share_contents: answer.share_contents,
              buttonType: answer.buttonType
            }
            elementObj.buttons.push(button);
          } else {
            let button = {
              type: 'element_share',
              buttonType: answer.buttonType
            }
            elementObj.buttons.push(button);
          }
          break;
        case 'download':
          elementObj.buttons.push({
            type: 'element_download',
            title: answerTitle,
            url: answer.shortUrl || answer.url || answer.value,
            buttonType: answer.buttonType
          });
          break;
        default:
          console.error(`Unrecognized answer type for carousel: ${answer.type}`.red);
          return;
      }
    }

    elements.push(elementObj);
  });

  var messageData = {
    appID: appID,
    attachment: {
      type: "template",
      payload: {
        template_type: "generic",
        elements: elements
      }
    }
  };
  return messageData;
}



export {
  getTextMessageData,
  getImageMessageData,
  getVideoMessageData,
  getCarouselMessageData,
  getAudioMessageData,
  getFileMessageData,
  getAdaptiveCardsMessageData,
}
