// SERVEUR
// -----
import { v4 as uuidv4 } from 'uuid';

import store from "./index";
import {defaultState, localStorageKey} from "./constants";
import {lsTest} from "./utils";

export const LOAD_STATE = (state, { urlAppID, uid }) => {
  // console.log('LOAD_STATE', urlAppID, uid);
  // In the worst case, state has a default value pre-established
  let appData = { ...JSON.parse(JSON.stringify(defaultState)), urlAppID, uid };
  if (lsTest()) {
    // Local storage is supported
    const storedDataJSON = localStorage.getItem(localStorageKey);
    const storedData = storedDataJSON ? JSON.parse(storedDataJSON) : appData;
    // console.log(`storedData.respondentID=${storedData.respondentID}`);
    if (storedData.appDataList) {
      // console.log('OK, storage has new version of the structure');
      let storedAppData = storedData.appDataList.find(
        ad => (ad.urlAppID === urlAppID || ad.appID === urlAppID) && ad.uid === uid
      );
      if (storedAppData) {
        // console.log('Ok, app found in stored appDataList', storedAppData);
        // Before that, here, we replaced appData by the content of storedAppData. But when adding some state variables,
        // this way of doing things led to broken reactivity for these new variables.
        // https://github.com/vizirco/dashboard/issues/1857
        appData = { ...appData, ...storedAppData }
      } else {
        // !ad.uid
        if (state.uid) {
          // console.log('state.uid = ', state.uid)
          let storedAppData = storedData.appDataList.find(
            ad => (ad.appID === state.appID || ad.urlAppID === state.urlAppID) && ad.uid === undefined
          );
          // console.log('storedAppData = ', storedAppData)
          if (storedAppData) {
            storedAppData.respondentID = state.uid;
            storedAppData.uid = state.uid;
            appData = { ...appData, ...storedAppData }
          }
        }
        console.log('App not found in stored list ; build it with default app state');
        appData.respondentID = (uid || storedData.respondentID);
      }
      // console.log('State loaded with respondentID=', appData.respondentID)
    }
    else {
      // If storage is in the old version, keep the respondentID...
      console.log('Oh, storage has never been set or has an old version of the structure ; build it or convert it');
      const respondentID = storedData.respondentID;
      // ... and set up the new local storage structure.
      appData.respondentID = respondentID;
      const newStoredData = { appDataList: [appData]};
      console.log('New stored data', newStoredData);
      localStorage.setItem(localStorageKey, JSON.stringify(newStoredData));
    }
  }
  store.replaceState(Object.assign({}, appData))
}

export const SEND_MESSAGE = (state, { text, quick_replies, attachment, stataiID, isLiveMessage, operator, fromHumanMessage }) => {
  // console.log('SEND_MESSAGE', state.appID, state.respondentID)
  let obj = {date : Date.now(), user : false}
  if(text) {
    obj['text'] = text
    store.commit('ADD_MESSAGE_TO_READING_LIST', obj)
  }
  if(attachment) obj['attachment'] = attachment
  if(stataiID) {
    obj['stataiID'] = stataiID
  }
  if (isLiveMessage) {
    obj['isLiveMessage'] = isLiveMessage
    obj['operator'] = operator
    obj['fromHumanMessage'] = fromHumanMessage
    // This is done here as it is the end of the "call stack"
    store.commit('HIDE_QUICKREPLIES')
  }

  if (state.widget == 'opened') {
    obj.last_active = new Date()
    obj.read = true
  }
  obj.messageId = uuidv4();
  state.messages.push(obj)
  state.quickReplies =  quick_replies || []
}

export const INIT_CONVERSATION = (state) => {
  state.messages = []
  state.quickReplies = []
}

export const WIDGET_OPEN = (state) => {
  if (state.messages.length > 0) {
    state.messages[state.messages.length - 1].read = true
  }
  state.widget = 'opened'
  state.last_active = new Date()
}
export const WIDGET_CLOSE = (state) => {
  state.widget = 'closed'
}
export const USER_ACTIVE = (state) => {
  state.last_active = new Date()
}

// CLIENT
export const CHANGE_NAVIGATEUR = (state, nav) => {
  state.navigateur = nav
}

export const SEND_ANSWER = (state, payload) => {
  state.messages.push({
    ...payload, date : Date.now(), user : true
  })
}

export const SAVE_APP_ID = (state, id) => {
  state.appID = id
}

export const SAVE_CHATBOT_ID = (state, id) => {
  state.chatbotID = id
}

export const EMPTY_CONVERSATION = (state) => {
  state.messages = []
}

export const SAVE_RESPONDENT = (state, rid) => {
  state.respondentID = rid
}

export const SAVE_ACCESS_TOKEN = (state, access_token) => {
  state.access_token = access_token
}

export const CHANGE_APPLICATION = (state, app) => {
  state.application = app
}

// Typing
// ------

export const SET_TYPING_OFF = (state) => {
  state.typing = false
}

export const SET_TYPING_ON = (state) => {
  state.typing = true
}

// Backdrop
// --------

export const SHOW_BACKDROP = (state) => {
  state.showBackdrop = true
}

export const HIDE_BACKDROP = (state) => {
  state.showBackdrop = false
  state.showQuickMenu = false
  state.filePreviewIsVisible = false
}

// QuickMenu
// ---------

export const SHOW_QUICKMENU = (state) => {
  state.showQuickMenu = true
  state.showBackdrop = true
}

export const HIDE_QUICKMENU = (state) => {
  state.showQuickMenu = false
  state.showBackdrop = false
}


// QuickReplies
// ---------

export const SHOW_QUICKREPLIES = (state) => {
  state.showQuickReplies = true
}

export const HIDE_QUICKREPLIES = (state) => {
  state.showQuickReplies = false
}

// Webview
export const SHOW_WEBVIEW = (state) => {
  state.showWebview = true
}
export const HIDE_WEBVIEW = (state) => {
  state.showWebview = false
}
export const SHOW_INFORMATIONS = (state) => {
  state.showInformations = true
}
export const HIDE_INFORMATIONS = (state) => {
  state.showInformations = false
}

export const CHANGE_WEBVIEW_URL = (state, url) => {
  state.webViewURL = url
}

export const CHANGE_SHOW_CHAT = (state, value) => {
  state.show_chat = value
}
export const NOTATION_VISIBLE = (state, value) => {
    state.notationVisible = value
}

export const MESSAGE_WITH_FEEDBACK_NLP = (state, data) => {
  state.messages[data.indexMessage].feedback = data.feedback;
}

export const SET_TEST_TOKEN = (state, token) => {
  state.test_token = token
}

export const CHANGE_LISTENING = (state) => {
  state.listening = !state.listening
}

export const SET_PROCESSING_VALUE = (state, value) => {
  state.processing = value
}

export const LOAD_SETTINGS = (state, recognition) => {
  // console.log('LOAD SETTINGS');
  // console.log(recognition);
  if (recognition !== false) {
    recognition.onstart = function() {
      console.log('Speech recognition started');
    };
    recognition.onsoundstart = function() {
      store.commit('SET_PROCESSING_VALUE', true)
      // state.processing = true;
    };
    recognition.onresult = function(event) {
      let transcript = event.results[event.resultIndex][0].transcript;

      // mobile repeat bug handling
      if (!(event.resultIndex == 1 && transcript == event.results[0][0].transcript)) {
        // pass transcript directly to processSpeech method
        store.commit('processSpeech', transcript);
        console.log('Speech: "' + transcript + '"');
      }

      // state.processing = false;
      store.commit('SET_PROCESSING_VALUE', false)
    };
    recognition.onend = function() {
      console.log('Speech recognition stopped');
      // state.processing = false;
      store.commit('SET_PROCESSING_VALUE', false)
      if (state.listening) {
        state.recognition.start();
      }
    };
    recognition.onerror = function(event) {
      console.log(event.error);
      if(event.error == 'no-speech') {
        console.log('No speech detected, try again');
        state.listening = false;
        // state.processing = false;
      }
    };
  }
  state.recognition = recognition;
}

export const processSpeech = (state, transcript) => {
  store.commit('SET_VOICE_STATUS_ACTIVE')
  store.commit('TOGGLE_LISTENING')
  if (transcript == 'reboot') {
    transcript = '/reboot'
  }
  store.dispatch('send_message',
    {
      payload: {
        message: { text: transcript }
      },
      userPayload: { text: transcript },
      isVoice: true
    }
  )
}

export const TOGGLE_LISTENING = (state) => {
  // State.listening can be undefined for old user
  if (!state.listening) {
    state.listening = true
  }else {
    state.listening = false
  }
  if (state.listening) {
    state.recognition.start()
  } else {
    state.recognition.stop()
  }

  // if (state.online) {
  //   console.log('online');
  //   state.recognition.start()
  // } else {
  //   console.log('offline');
  //   state.recognition.stop()
  // }
  // state.online ? state.recognition.start() : state.recognition.stop();
}

export const SET_VOICE_STATUS_ACTIVE = (state) => {
  state.isLastMessageVoice = true
}
export const SET_VOICE_STATUS_INACTIVE = (state) => {
  state.isLastMessageVoice = false
}

export const ADD_MESSAGE_TO_READING_LIST = (state, message) => {
  if (state.messagesToRead == undefined) state.messagesToRead = []
  if (state.messagesToRead.length === 0) {
    // allow to read the first message automatically
    message.isFirstMessage = true
  }
  state.messagesToRead.push(message)
}

export const REMOVE_MESSAGE_TO_READING_LIST = (state) => {
  state.messagesToRead.splice(0,1)
}
export const REMOVE_ALL_MESSAGE_TO_READING_LIST = (state) => {
  state.messagesToRead = []
}

export const SET_INITIAL_RESOURCE = (state, resource) => {
  state.initialResource = resource;
}

export const SET_UID = (state, uid) => {
  state.uid = uid;
}

export const SET_IS_TEST_USER = (state, isTestUser) => {
  state.isTestUser = isTestUser;
}

export const SET_REF = (state, ref) => {
  state.ref = ref;
}

export const SET_IN_IFRAME = (state, isInIframe) => {
  state.isInIframe = isInIframe;
};

export const SET_WIDGET_PARENT_URL = (state, url) => {
  state.widgetParentUrl = url;
};

export const SET_LOGGED_IN = (state, loggedIn) => {
  state.loggedIn = loggedIn;
};

export const SET_SOCKET_IS_READY = (state, socketIsReady) => {
  state.socketIsReady = socketIsReady;
};

export const SET_AVAILABLE_OPERATORS = (state, operators) => {
  // console.log('SET_AVAILABLE_OPERATORS')
  state.availableOperators = operators
}

export const ADD_AVAILABLE_OPERATOR = (state, operator) => {
  // console.log('ADD_AVAILABLE_OPERATORS', operator)
  const operatorIdx = state.availableOperators.findIndex(o => o.id === operator.id)
  if (operatorIdx >= 0) {
    state.availableOperators.splice(operatorIdx, 1, operator)
  } else {
    state.availableOperators.push(operator)
  }
}

export const REMOVE_AVAILABLE_OPERATOR = (state, operator) => {
  // console.log('REMOVE_AVAILABLE_OPERATOR', operator)
  state.availableOperators = state.availableOperators.filter(o => o.id !== operator.id)
}

export const UPDATE_AVAILABLE_OPERATOR  = (state, data) => {
  // console.log('UPDATE_AVAILABLE_OPERATOR', state, data)
  state.availableOperators = state.availableOperators.map(o => {
    if (o.id === data.operatorID) {
      return { ...o, isAssigned: data.isAssigned }
    } else {
      return o
    }

  })
  // console.log('state.availableOperators=', JSON.stringify(state.availableOperators))
}

export const OPEN_FILE_PREVIEW = (state, file) => {
  state.previewedFile = JSON.parse(JSON.stringify(file))
  state.showBackdrop = true
  state.filePreviewIsVisible = true
}

export const CLOSE_FILE_PREVIEW = (state) => {
  state.filePreviewIsVisible = false
  state.showBackdrop = false
  state.previewedFile = undefined
}

export const CHANGE_LIVE_STATUS = (state, isLive) => {
  state.isLive = isLive;
}

export const UPDATE_LOCAL_RESPONDENT_MEMORY = (state, newMemory) => {
  // console.log('UPDATE_LOCAL_RESPONDENT_MEMORY', state.localRespondentMemory, newMemory);
  state.localRespondentMemory = { ...state.localRespondentMemory, ...newMemory}
  console.log('Last state of localRespondentMemory =', state.localRespondentMemory);
}

export const RESET_LOCAL_RESPONDENT_MEMORY = (state) => {
  // console.log('RESET_LOCAL_RESPONDENT_MEMORY', state.localRespondentMemory);
  state.localRespondentMemory = {}
  console.log('Last state of localRespondentMemory =', state.localRespondentMemory);
}

export const SET_CURRENT_CONTEXT_RESOURCE_ID = (state, resourceID) => {
  // console.log('SET_CURRENT_CONTEXT_RESOURCE_ID', state.currentContextResourceID, resourceID);
  state.currentContextResourceID = resourceID
}

export const SET_LAST_AUTO_OPEN_AT = (state, autoOpenAt) => {
  // console.log('SET_LAST_AUTO_OPEN_AT', state.lastAutoOpenAt, autoOpenAt);
  state.lastAutoOpenAt = autoOpenAt
}
