<template>
  <section></section>
</template>

<script>
export default {
  name: 'Authcallbackclose',
  components: {},
  mounted() {
    window.close();
  },
  methods: {},
};
</script>
<style lang="scss"></style>
