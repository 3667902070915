<template>
  <div class="v3">
    <div
      class="Chat"
    >
      <notation v-if="notationVisible" />
      <top-bar v-if="topBarVisible" />
      <backdrop v-if="backdropVisible" />
      <file-preview v-if="filePreviewIsVisible"/>
      <div
        class="Chat-content"
        @scroll="onScroll"
      >
        <discussion />
      </div>
      <webview v-if="webViewVisible" />
      <informations v-if="informationsVisible" />
      <div v-if="hideInputBar != true" :class="['Chat-input', {'new': newInputBar}]">
        <input-message v-if="!newInputBar"/>
        <new-input-message v-if="newInputBar"/>
      </div>
    </div>
  </div>
</template>

<script>
  const notation = () => import('../components/notation.vue')
  const Webview = () => import('../components/v3/Webview.vue')
  const Informations = () => import('../components/v3/Informations.vue')
  const TopBar = () => import('../components/v3/TopBar.vue')
  const Discussion = () => import('../components/v3/Discussion.vue')
  const InputMessage = () => import('../components/v3/InputMessage.vue')
  const NewInputMessage = () => import('../components/v3/NewInputMessage.vue')
  const Backdrop = () => import('../components/v3/Backdrop.vue')
  const FilePreview = () => import('../components/v3/FilePreview.vue')
  // import Discussion from '../components/v3/Discussion';
  // import InputMessage from '../components/v3/InputMessage';
  // import notation from '../components/notation';
  // import Webview from '../components/v3/Webview';
  // import Informations from '../components/v3/Informations'
  // import TopBar from '../components/v3/TopBar';
  // import Backdrop from '../components/v3/Backdrop';
  // import FilePreview from './v3/FilePreview'
  import {mapState} from 'vuex'

  export default {
    name: 'VizirChatbot',
    components: {
      FilePreview,
      TopBar,
      Webview,
      Discussion,
      InputMessage,
      Backdrop,
      Informations,
      notation,
      NewInputMessage
    },
    data () {
      return {
        lastScroll: []
      }
    },
    computed: {
      ...mapState({
        isInIframe: 'isInIframe',
        filePreviewIsVisible: 'filePreviewIsVisible',
      }),
      webViewVisible () {
        return this.$store.getters.webViewVisible
      },
      notationVisible () {
        return this.$store.getters.notationVisible
      },
      informationsVisible () {
        return this.$store.getters.informationsVisible
      },
      topBarVisible () {
        return this.$store.getters.topBarVisible
      },
      backdropVisible () {
        return this.$store.getters.backdropVisible
      },
      newInputBar () {
        return this.$store.getters.newInputBar
      },
      hideInputBar() {
        return this.$store.getters.hideInputBar
      }
    },

    watch: {
      filePreviewIsVisible(val) {
        // Managing file preview visibility here highly simplify code (ie, no need to duplicate "postMessage" call
        // in several mutations/actions)
        if (this.isInIframe) {
          const message = val ? 'Maximize' : 'Minimize'
          window.top.postMessage(JSON.stringify({type: `vizir${message}Widget`}), '*')
        }
      }
    },

    created () {
      if (this.$route.query.first_login == 'true') {
        let userPayload = '/reboot_login';
        if (this.$route.query.first_login == 'true') userPayload = '/first_login'
        this.$store.dispatch('send_message',
          {
            payload: {
              message: { text: '/reboot' }
            },
            userPayload: { text: userPayload }
          }
        )
        this.$router.push({path: this.$route.path, query: {}})
        .catch(err => {
          console.error(err.message);
        })
        // this.$route.query.logged_in == 'false'
      }
      else if (this.$route.query.logged_in == 'true') {
        this.$router.push({path: this.$route.path, query: {}})
        .catch(err => {
          console.error(err.message);
        })
      }
    },

    methods: {
      onScroll(e){
        let current = e.target.scrollTop
        //fix bounce inertie IOS
        this.lastScroll.push(current)
      }
    }
  }

</script>

<style lang="scss" src="../assets/scss/app_v3.scss"></style>
<style lang="scss" src="../assets/scss/app_chatbot_v2.scss"></style>
<style lang="scss" scoped>
  .Chat-input.new{
    padding: 0;
  }
</style>
