// import moment from "moment";
import cssVars from 'css-vars-ponyfill';
import { HTTP_SOCKET } from '../common/http';
import DOMPurify from 'dompurify';
import { localStorageKey} from "./constants";

function lsTest() {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    // console.log('OK, local storage can be used.');
    return true;
  } catch (e) {
    console.log(
      'Cannot use local storage on this browser.' +
        ' As a consequence, simultaneous conversations will not be possible.'
    );
    return false;
  }
}

function autoOpenWidget(application, state, commit) {
  // console.log('autoOpenWidget', state);
  let timing = 300;
  if (application && application.info && application.design.show_widget_delay) {
    timing = application.design.show_widget_delay;
  }

  let lastAutoOpenWasAWhileAgo = true;
  const lastAutoOpenAt = state.lastAutoOpenAt;
  if (lastAutoOpenAt) {
    // console.log('Time since last AutoOpen', (Date.now() - lastAutoOpenAt)/1000);
    // Two auto open must be seperated by 1 hour.
    const durationBetweenTwoAutoOpen = 1 * 60 * 60 * 1000;
    if (Date.now() - lastAutoOpenAt < durationBetweenTwoAutoOpen) {
      // console.log('AutoOpen cannot be too frequent; block this one.');
      lastAutoOpenWasAWhileAgo = false;
    }
  }

  if (application?.design?.auto_open_widget && lastAutoOpenWasAWhileAgo) {
    // console.log('AutoOpen can be performed.');
    setTimeout(function () {
      window.top.postMessage(
        JSON.stringify({ type: 'widgetWantsToBeAutoOpen' }),
        '*'
      );
        // Remember that this is the last time widget was auto open
        commit('SET_LAST_AUTO_OPEN_AT', Date.now())
    }, timing);
  }
}

function setupApplication(application, state, commit) {
  // console.log('setupApplication', application)

  let json = { type: 'getApplication', application: application };
  window.top.postMessage(JSON.stringify(json), '*');

  autoOpenWidget(application, state, commit);

  if (
    application &&
    application.notation &&
    application.notation.active === true
  ) {
    commit('NOTATION_VISIBLE', true);
  }
  if (application && application.design) {
    addPersonnalizedCss(application.design.css || {});
    let background = '#f7f7f7';
    let mobile_background = '#f7f7f7';
    let variables_application = {};
    if (application.design['color-background']) {
      background = application.design['color-background'];
      mobile_background = application.design['color-background'];
    }
    if (
      application.design['image-background'] &&
      application.design['image-background'] !== 'false' &&
      application.design['image-background'].length > 5
    )
      background = 'url("' + application.design['image-background'] + '")';
    document.body.style.setProperty('--background-color', background);
    variables_application['background-color'] = background;

    // version mobile
    if (
      application.design['mobile-image-background'] &&
      application.design['mobile-image-background'] !== 'false' &&
      application.design['mobile-image-background'].length > 5
    )
      mobile_background =
        'url("' + application.design['mobile-image-background'] + '")';
    document.body.style.setProperty(
      '--mobile-background-color',
      mobile_background
    );
    variables_application['mobile-background-color'] = mobile_background;

    if (application.design.color) {
      document.body.style.setProperty(
        '--message-background-color',
        application.design.color
      );
      variables_application['primary-color'] = application.design.color;
      variables_application['message-background-color'] =
        application.design.color;
    }
    if (application.design['color-message']) {
      variables_application['message-color'] =
        application.design['color-message'];
      document.body.style.setProperty(
        '--message-color',
        application.design['color-message']
      );
    }
    if (application.design['message-color-text']) {
      variables_application['message-color-text'] =
        application.design['message-color-text'];
      document.body.style.setProperty(
        '--message-color-text',
        application.design['message-color-text']
      );
    }
    if (application.design['reponse-color-text']) {
      variables_application['reponse-color-text'] =
        application.design['reponse-color-text'];
      document.body.style.setProperty(
        '--reponse-color-text',
        application.design['reponse-color-text']
      );
    }
    if (application.design['color-header']) {
      variables_application['color-header'] =
        application.design['color-header'];
      document.body.style.setProperty(
        '--color-header',
        application.design['color-header']
      );
    }
    if (application.design['color-header-titre']) {
      variables_application['color-header-titre'] =
        application.design['color-header-titre'];
      document.body.style.setProperty(
        '--color-header-titre',
        application.design['color-header-titre']
      );
    }
    // Quick replies buttons color
    if (application.design['color-button-default']) {
      variables_application['color-button-default'] =
        application.design['color-button-default'];
      document.body.style.setProperty(
        '--color-button-default',
        application.design['color-button-default']
      );
    }
    if (application.design['color-button-success']) {
      variables_application['color-button-success'] =
        application.design['color-button-success'];
      document.body.style.setProperty(
        '--color-button-success',
        application.design['color-button-success']
      );
    }
    if (application.design['color-button-danger']) {
      variables_application['color-button-danger'] =
        application.design['color-button-danger'];
      document.body.style.setProperty(
        '--color-button-danger',
        application.design['color-button-danger']
      );
    }
    if (Object.keys(variables_application).length > 0) {
      cssVars({
        rootElement: document,
        shadowDOM: false,
        include: 'link[rel=stylesheet],style',
        exclude: '',
        variables: variables_application,
        // onWarning(message) {
        //   // console.log(message); // 1
        //   // console.log('warning');
        // },
        // onComplete(cssText, styleNode, cssVariables, benchmark) {
        //   // ...
        //   // console.log(styleNode);
        //   // console.log(cssVariables);
        //   // console.log(benchmark);
        //   // console.log('TEST');
        //   // console.log(cssText);
        // }
      });
    }
  }

  if (application) {
    if (
      application &&
      application.design &&
      application.design.picto &&
      application.design.picto.url
    ) {
      document.querySelector("link[rel*='icon']").href =
        application.design.picto.url;
    }
    if (
      application &&
      application.design &&
      application.design.ga &&
      application.design.ga.active === true
    ) {
      let ga = application.design.ga.code;
      var code = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${ga}');
      `;
      let script_tag = document.createElement('script');
      script_tag.text = code;
      var script_google = document.createElement('script');
      script_google.src = 'https://www.googletagmanager.com/gtag/js?id=' + ga;

      document.head.appendChild(script_tag);
      document.head.appendChild(script_google);
    } else if (
      application &&
      application.design &&
      application.design.gtm &&
      application.design.gtm.active === true
    ) {
      let gtm = application.design.gtm.code;
      let code = `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtm}');
      `;
      let script_tag = document.createElement('script');
      script_tag.text = code;
      let no_script = document.createElement('noscript');
      let script_google = `
          <iframe src="https://www.googletagmanager.com/ns.html?id=${gtm}"
          height="0" width="0" style="display:none;visibility:hidden">
      `;
      no_script.text = script_google;
      document.head.appendChild(script_tag);
      document.head.appendChild(no_script);
    } else {
      // let ga = 'UA-120930462-1'
    }

    if (application && application && application.description)
      document.title = application.description;
    // console.log(application);
    commit('CHANGE_APPLICATION', application);

    if (application.availableOperators) {
      commit('SET_AVAILABLE_OPERATORS', application.availableOperators);
    }

    if (application?.design?.localStorageDisabled) {
      // console.log('****** LOCAL STORAGE DISABLED ***');
      // If local storage is disabled at app level, remove any entry we
      // may have saved to it.
      if (lsTest()) {
        localStorage.removeItem(localStorageKey)
        localStorage.removeItem('vizirNotificationLastDisplayedAt')
      }
      commit('CHANGE_APPLICATION', application);
    }

    if (!application) {
      commit('SEND_MESSAGE', {
        text: 'No such app, please contact the person who send you this url',
      });
      commit('SET_TYPING_OFF');
    }
  }
}

async function initBeforeSendingFirstMessage(
  state,
  payload,
  commit,
  dispatch,
  vueApp
) {
  // console.log('initBeforeSendingFirstMessage, appID=', state.appID);
  if (!payload.message) {
    // The user starts with a burger menu button
    payload.message = {
      text: payload.postback.payload,
    };
  }
  // https://github.com/vizirco/dashboard/issues/3475
  let respondentID = undefined;
  if (state.uid && state.uid !== 'null' && state.uid !== 'undefined') {
    respondentID = state.uid;
  }
  const respondentInitData = {
    appID: state.appID,
    respondentID: respondentID,
    ref: state.ref,
    application_title: state.application.title,
    userResponse: payload.message.text,
    source: 'web',
    initialMessages: state.initialResource.messages,
    initialFeatureID: state.initialResource.featureID,
    initialResourceID: state.initialResource._id,
    widgetParentUrl: state.widgetParentUrl,
    isTestUser: state.isTestUser,
    respondentMemory: state.localRespondentMemory
  };
  try {
    const res = await HTTP_SOCKET.post('/botrespondents', respondentInitData);
    if (res.data.status) {
      const respondentID = res.data.respondentID;
      // console.log('New respondent ID =', respondentID);
      commit('SAVE_RESPONDENT', respondentID);
      // Socket can now be opened
      vueApp.$socket.client.open();
      const obj = {
        recipient: { id: state.appID },
        sender: { id: state.respondentID },
        operatorsRequested: true,
      };
      dispatch('initSocketWithMessage', obj);
      // A bit of local storage cleanup
      commit('RESET_LOCAL_RESPONDENT_MEMORY');
    } else {
      throw new Error('Data received are invalid : res.data.status != true');
    }
  } catch (e) {
    console.error('Error while creating new respondent', e.message);
  }
}

function sanitizeMessageText(text) {
  return DOMPurify.sanitize(text);
}

async function finalizeMessageData({ dispatch }, { payload, userPayload }) {
  // Sanitize message content
  if (payload?.message?.text) {
    payload.message.text = sanitizeMessageText(payload.message.text);
  }
  if (userPayload?.text) {
    userPayload.text = sanitizeMessageText(userPayload.text);
  }

  // If attachments contains files, get signed urls for them (only for
  // userPayload that will be used here in newchat. For outbound data, dashboard
  // will get signed url when necessary)
  if (userPayload?.attachment?.length > 0) {
    userPayload.attachment = await Promise.all(
      userPayload.attachment.map(async (a) => {
        if (a?.payload?.type === 'file') {
          return {
            ...a,
            payload: {
              ...a.payload,
              url: await dispatch('getSignedGetUrlForUpload', a.payload.url),
            },
          };
        } else {
          return a;
        }
      })
    );
  }
  return { payload, userPayload };
}

function addPersonnalizedCss(css) {
  if (css.active) {
    const style = document.createElement('style');

    style.textContent = `
      ${css.value}
    `;

    document.head.appendChild(style);
  }
}

export {
  lsTest,
  setupApplication,
  initBeforeSendingFirstMessage,
  finalizeMessageData,
};
