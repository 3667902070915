import moment from 'moment';
import { REINIT_INTERVAL } from './constants';

const messages = (state) => JSON.parse(JSON.stringify(state.messages));
const application = (state) => JSON.parse(JSON.stringify(state.application));
const persistent_menu = (state) => {
  if (state.application) {
    return state.application.persistent_menu;
  } else {
    return null;
  }
};

const hideInputBar = (state) => {
  if (state.application && state.application.design) {
    return state.application.design.hideInputBar || false;
  }
  else return false;
}

const title = (state) => {
  if (
    state.application &&
    state.application.design &&
    state.application.design.showTitle
  ) {
    return state.application.title;
  } else {
    return null;
  }
};

const description = (state) => {
  if (
    state.application &&
    state.application.design &&
    state.application.design.showDescriptionInHeader
  ) {
    return state.application.description;
  } else {
    return null;
  }
};
const avatar = (state) => {
  if (
    state.application &&
    state.application.design &&
    state.application.design.picto &&
    state.application.design.picto.url
  ) {
    return state.application.design.picto.url;
  } else {
    return 'public/static/images/picto/picto-f6.png';
  }
};
const logo = (state) => {
  if (
    state.application &&
    state.application.design &&
    state.application.design.logo &&
    state.application.design.logo.url
  ) {
    return state.application.design.logo.url;
  } else {
    return 'public/static/images/picto/vizir-logo-white@2x.png';
  }
};
const showInformationWindow = (state) => {
  if (
    state.application &&
    state.application.info &&
    state.application.info.showInformationWindow
  ) {
    return state.application.info.showInformationWindow;
  } else {
    return false;
  }
};
const helpText = (state) => {
  if (
    state.application &&
    state.application.info &&
    state.application.info.description_button_hover
  ) {
    return state.application.info.description_button_hover;
  } else {
    return 'Aide';
  }
};
const restartHelpText = (state) => {
  if (
    state.application &&
    state.application.design &&
    state.application.design.restart_button_text
  ) {
    return state.application.design.restart_button_text;
  } else {
    return 'Recommencer';
  }
};
const showRestartButton = (state) => {
  // Restart button is not visible when in Live Mode
  return state?.application?.design?.showRestartButton && !state.isLive;
};

const restartButtonPosition = (state) => {
  if(
    state.application &&
    state.application.design &&
    state.application.design.restartButtonPosition
  ) {
    return state.application.design.restartButtonPosition;
  }else{
    return false;
  }
}
const isInIframe = (state) => {
  return state.isInIframe;
};

const picto = (state) => {
  if (
    state.application &&
    state.application.info &&
    state.application.design.picto &&
    state.application.design.picto.url
  ) {
    return state.application.design.picto.url;
  } else {
    return 'public/static/images/picto/info.svg';
  }
};
const topBarVisible = (state) => {
  if (
    state.application &&
    state.application.design &&
    state.application.design.showTopBar === false
  ) {
    return state.application.design.showTopBar;
  } else {
    return true;
  }
};

const newInputBar = (state) => {
  if (state.application && state.application.design)
    return state.application.design.newInputBar;
  else return false;
}
const uploadButtonText = (state) => {
  if(state.application && state.application.design)
    return state.application.design.upload_button_text;
  else return undefined;
};
const photoButtonText = (state) => {
  if(state.application && state.application.design)
    return state.application.design.photo_button_text;
  else return undefined;
};

const appID = (state) => state.appID;
const quickReplies = (state) => state.quickReplies;
const respondentID = (state) => state.respondentID;

const typing = (state) => state.typing;

const quickRepliesVisible = (state) => state.showQuickReplies;
const backdropVisible = (state) => state.showBackdrop;
const quickMenuVisible = (state) => state.showQuickMenu;

const webViewVisible = (state) => state.showWebview;
const informationsVisible = (state) => state.showInformations;
const webViewURL = (state) => state.webViewURL;
const notationVisible = (state) => state.notationVisible;
const notationObject = (state) => state.application.notation;

const unfoldParagraphText = (state) =>
  state.application.design.unfoldParagraphText;

const navigateur = (state) => state.navigateur;

const primaryColor = (state) => {
  if (
    state.application &&
    state.application.design &&
    state.application.design.primaryColor
  ) {
    return state.application.design.primaryColor;
  } else {
    return '#3BDDB8';
  }
};
const show_chat = (state) => state.show_chat;
const isVoiceActive = (state) => {
  if (
    state.application &&
    state.application.design &&
    state.application.design.voice
  ) {
    return state.application.design.voice.active;
  } else {
    return false;
  }
};
const isLastMessageVoice = (state) => {
  // Sometimes it is undefined
  if (state.isLastMessageVoice) {
    return state.isLastMessageVoice;
  } else {
    return false;
  }
};

const listening = (state) => state.listening;

const messagesToRead = (state) => state.messagesToRead;

const default_language = (state) => state.application.default_language;

const availableOperators = (state) => state.availableOperators;

const shouldReinitConversationAfterAWhile = (state) => {
  // Related to https://github.com/vizirco/dashboard/issues/1651
  // Conversation may be reinit if :
  // 1/ Respondent has been created in DB (<=> respondentID is defined)
  // 2/ Reinit feature is activated on application
  // 3/ It's been a while that the respondent has not interacted with the bot

  // console.log('shouldReinitConversationAfterAWhile')
  // console.log(state.respondentID)
  // console.log(state.application.design.shouldReinitConversation)
  // console.log(moment().diff(state.last_active) > REINIT_INTERVAL)
  return (
    state.respondentID &&
    state.application.design.shouldReinitConversation &&
    moment().diff(state.last_active) > REINIT_INTERVAL
  );
};

export {
  photoButtonText,
  uploadButtonText,
  messages,
  navigateur,
  typing,
  respondentID,
  application,
  appID,
  quickReplies,
  persistent_menu,
  title,
  description,
  avatar,
  logo,
  picto,
  helpText,
  showRestartButton,
  restartButtonPosition,
  restartHelpText,
  backdropVisible,
  quickMenuVisible,
  quickRepliesVisible,
  webViewVisible,
  informationsVisible,
  webViewURL,
  showInformationWindow,
  notationVisible,
  notationObject,
  primaryColor,
  topBarVisible,
  show_chat,
  isVoiceActive,
  isLastMessageVoice,
  listening,
  messagesToRead,
  isInIframe,
  availableOperators,
  shouldReinitConversationAfterAWhile,
  unfoldParagraphText,
  default_language,
  newInputBar,
  hideInputBar
};
