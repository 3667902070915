<template>
  <div v-if="show_chat" id="app">
    <router-view />
  </div>
</template>

<script>
import $store from './store/index';
import { mapActions } from 'vuex';

export default {
  name: 'App',
  computed: {
    // TODO: use mapGetters
    show_chat() {
      return this.$store.getters.show_chat;
    },
    shouldReinitConversationAfterAWhile() {
      return this.$store.getters.shouldReinitConversationAfterAWhile;
    },
  },

  watch: {
    show_chat: function (newValue) {
      // This allows to overwrite the default style of the webchat via url query
      if (newValue) {
        this.$nextTick(function () {
          if (this.$route.query) {
            let computedStyleVariables = '';
            for (const key in this.$route.query) {
              if (key.includes('--'))
                computedStyleVariables += `${key}: #${this.$route.query[key]};`;
            }
            document.querySelector('#app').style = computedStyleVariables;
          }
        });
      }
    },
  },

  mounted() {
    // console.log('App component mounting');
    this.$nextTick(function () {
      window.addEventListener('message', this.messageReceived);
    });
  },
  beforeCreate: function () {
    this.$store.commit('CHANGE_SHOW_CHAT', false);
  },

  methods: {
    ...mapActions(['isUserKnown']),
    async messageReceived(event) {
      // console.log('DOM event received by iframe = ', event)
      let eventData;
      try {
        eventData = JSON.parse(event.data);
      } catch (err) {
        eventData = event.data;
      }
      // console.log(
      //   'App.vue | event =',
      //   eventData.type,
      //   'eventData = ',
      //   eventData
      // );
      if (eventData?.type === 'open') {
        // console.log('>>>>>>Open event received')

        if (this.shouldReinitConversationAfterAWhile) {
          // console.log('Too much time elapsed ; need to reinit conversation')
          await this.$store.dispatch('autoReinitConversation');
        }

        $store.commit('WIDGET_OPEN');
        if (document.querySelector('.Chat-content')) {
          document.querySelector('.Chat-content').scrollTop =
            document.querySelector('.Chat-content').scrollHeight;
        }
        setTimeout(function () {
          if (document.querySelector('.Chat-content')) {
            document.querySelector('.Chat-content').scrollTop =
              document.querySelector('.Chat-content').scrollHeight;
          }
        }, 2000);
        setTimeout(function () {
          if (document.querySelector('.Chat-content')) {
            document.querySelector('.Chat-content').scrollTop =
              document.querySelector('.Chat-content').scrollHeight;
          }
        }, 5000);

        if (eventData?.payload?.widgetParentUrl) {
          this.$store.commit(
            'SET_WIDGET_PARENT_URL',
            eventData.payload.widgetParentUrl
          );
        }
      } else if (eventData.type === 'close') {
        // console.log('>>>>>>Close event received')
        $store.commit('WIDGET_CLOSE');
      } else if (eventData.type === 'sendWidgetParentUrl') {
        if (eventData?.payload?.widgetParentUrl) {
          this.$store.commit(
            'SET_WIDGET_PARENT_URL',
            eventData.payload.widgetParentUrl
          );
        }
      } else if (eventData.type === 'connection_done') {
        if (event.isTrusted && event.origin === window.location.origin) {
          // The user is now logued in I have in eventData the uid and the token
          this.$store.commit('SAVE_RESPONDENT', eventData.uid);
          this.$store.commit('SAVE_ACCESS_TOKEN', eventData.access_token);
          // console.log('>>>>>>connection_done event received')
          let data = {
            appID: this.$store.state.appID,
            respondentID: this.$store.state.respondentID,
            ref: this.$route.query.ref,
            logged_in: true,
          };
          // Get the respondentID and start the conversation
          let isSameUser = await this.isUserKnown(data);
          if (isSameUser) {
            // If respondent is known, socket can be open right now
            // console.log('Normal case; about to open socket')
            this.$socket.client.open();
          }
        }
      } else if (eventData.type === 'event_tracking_requested') {
        // console.log('Event "event_tracking_requested" received')
        if (eventData?.payload?.eventTracked) {
          await this.$store.dispatch('trackEvent', {
            name: eventData?.payload?.eventTracked,
          });
        }
      } else if (eventData.type === 'send_message_to_bot') {
        // console.log('Event "send_message_to_bot" received! ', eventData);
        this.$store.commit('EMPTY_CONVERSATION');
        // This is required to make sure message is interpreted in the context
        // of bot introduction.
        this.$store.commit(
          'SET_CURRENT_CONTEXT_RESOURCE_ID',
          this.$store.state.initialResource?._id
        );
        await this.$store.dispatch('send_message', {
          payload: {
            message: { text: eventData.messageText },
            trigger: { name: 'widget_redirection' },
          },
          userPayload: { text: eventData.messageText },
        });
      } else if (eventData.type === 'set_respondent_memory') {
        console.log('Event "set_respondent_memory" received! ', eventData);
        if (this.$store.state.respondentID) {
          // Case: "Respondent has already sent a message to the bot and hence
          // is already created in DB" => set memory via "initializeRespondent"
          const data = {
            appID: this.$store.state.appID,
            respondentID: this.$store.state.respondentID,
            memory: eventData.memory,
          };
          await this.$store.dispatch('isUserKnown', data);
        } else {
          // Case: "Respondent has not yet answered to bot and hence only
          // exists locally on browser (not yet created in DB)". => set memory
          // locally (and it will be forwarded with first message sent by the
          // user.
          this.$store.commit(
            'UPDATE_LOCAL_RESPONDENT_MEMORY',
            eventData.memory
          );
        }
      } else if (eventData.type === 'load_content') {
        console.log('Event "load_content" received! ', eventData);
        console.log(
          'this.$store.state.respondentID=',
          this.$store.state.respondentID
        );
        // Either requestedFeatureID or requestedResourceID is defined, not both.
        const { featureID, resourceID } = eventData;
        // Args are passed through refs as it will be more practical to have
        // this feature accessible from plain web chat (in iframe or not)
        const ref = resourceID
          ? `requestedResourceID:${resourceID}`
          : `requestedFeatureID:${featureID}`;
        const data = {
          appID: this.$store.state.appID,
          respondentID: this.$store.state.respondentID,
          ref,
        };
        await this.$store.dispatch('isUserKnown', data);
      }
    },
  },
};
</script>
