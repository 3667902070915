import { render, staticRenderFns } from "./V3.vue?vue&type=template&id=0765ee6a&scoped=true&"
import script from "./V3.vue?vue&type=script&lang=js&"
export * from "./V3.vue?vue&type=script&lang=js&"
import style0 from "../assets/scss/app_v3.scss?vue&type=style&index=0&prod&lang=scss&"
import style1 from "../assets/scss/app_chatbot_v2.scss?vue&type=style&index=1&prod&lang=scss&"
import style2 from "./V3.vue?vue&type=style&index=2&id=0765ee6a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0765ee6a",
  null
  
)

export default component.exports