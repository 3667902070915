export const localStorageKey = 'vizir';

export const defaultState = {
  // Enable Socket.io
  show_chat: false,
  notationVisible: false,

  // Messages states
  respondentID: undefined,
  access_token: undefined,
  messages: [],
  navigateur: 1,
  typing: true,
  quickReplies : [],

  //Application
  chatbotID : '', // is only used when uploading file
  appID : '',
  urlAppID: '',
  application: {},

  // test for preprod
  // is production is not used since the env/version (version 2.x)
  // isProduction: true,
  test_token: undefined,

  // Backdrop
  showBackdrop: false,
  // QuickMenu
  showQuickMenu: false,
  showQuickReplies : false,
  primaryColor: '',
  //showWebview
  showWebview: false,
  showInformations: false,
  webViewURL: '',
  showInformationWindow: false,
  topBarVisible: true,
  filePreviewIsVisible: false,


  //widget
  widget: 'closed',
  delay: 24*60*60*1000, // After 1 day I show the notification again
  last_active: new Date(),

  // voice
  messagesToRead: [],
  audioMessage: '',
  listening: false,
  processing: false,
  isLastMessageVoice: false,

  // init conversation
  initialResource: {},
  uid: undefined,
  ref: undefined,

  isInIframe: false,
  widgetParentUrl: undefined,
  loggedIn: false,

  // Allows to check if socket link between sender and recipient is ready (!= socket is connected)
  socketIsReady: false,

  availableOperators: [],

  previewedFile: undefined,

  // Is the current user a test user (eg, from widget on dashboard)?
  isTestUser: false,

  isLive: false,

  localRespondentMemory: {},

  currentContextResourceID: undefined,

  // Used to prevent widget to reopen automatically to ofter, for instance
  // when navigating accros several pages of the same website.
  lastAutoOpenAt: undefined,
}

// If option is activated for this application, conversation is reinitialized if user has not interacted in the
// last 10 minutes
export const REINIT_INTERVAL = 10 * 60 * 1000
