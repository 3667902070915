import Vue from 'vue'
import Router from 'vue-router'
import V3 from '@/components/V3'
import Authcallback from '@/components/Authcallback'
import Authcallbackclose from '@/components/Authcallbackclose'
Vue.use(Router)

const router =  new Router({
  mode: 'history',
  routes: [
    {
      path: '/n/:appID',
      name: 'nAndAppID',
      component: V3
    },
    {
      path: '/:appID',
      name: 'appID',
      component: V3
    },
    {
      path: '/v3/:appID',
      name: 'v3AndAppID',
      component: V3
    },
    {
      path: '/:appID/saml/sso/callback',
      name: 'Authcallback',
      component: Authcallback
    },
    {
      path: '/:appID/saml/sso/callback/success',
      name: 'Authcallbackclose',
      component: Authcallbackclose
    },
    
  ]
})

export default router
