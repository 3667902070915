import Vue from 'vue';

const fakeThis = new Vue();
import _ from 'lodash';

import { HTTP_SOCKET } from './../common/http';
import router from '../router'; // vue-router instance
import {
  setupApplication,
  initBeforeSendingFirstMessage,
  finalizeMessageData,
} from './utils';
import { displayFirstResourceContent } from './firstResourceMessagesUtils/main';

export async function isUserKnown({ commit, dispatch, state }, data) {
  // console.log('--------------isUserKnown', data)
  // console.log('appID', data.appID)
  // console.log('respondentID', data.respondentID)
  let logged_in = data.logged_in;
  data.test_token = state.test_token;
  data.vz_access_token = state.access_token;

  if (state.isInIframe) {
    const eventData = { type: 'getWidgetParentUrl' };
    window.top.postMessage(JSON.stringify(eventData), '*');
    // This "pause" is necessary as postMessage is asynchronous and we must be sure that parent url forwarding
    // is complete before moving the next step
    await new Promise((resolve) => setTimeout(resolve, 50));
    data.widgetParentUrl = state.widgetParentUrl
  }

  const requestedFeatureID = data.ref?.includes('requestedFeatureID');
  const requestedResourceID = data.ref?.includes('requestedResourceID');
  const contentRequested = Boolean(requestedFeatureID || requestedResourceID)

  // console.log('isUserKnown', data)
  return new Promise((resolve) => {
    HTTP_SOCKET.post('/initializeRespondent', data)
      .then(async (response) => {
        // console.log('POST /initializeRespondent response', response);
        if (response.data.success === false) {
          // This is where are processed errors coming mainly from mainworker
          console.error(
            'Error when initializing respondent on our server :/',
            response
          );
          console.error(response.data.message);
          return;
        }
        else if (response.data?.firstMessage?.type && response.data.firstMessage.type == 'redirect'){
          window.location.replace(response.data.firstMessage.url);
          return;
        }

        // Is it the same user as I already have in the store ?
        let isSameUser = false;
        console.log('______________')
        console.log('respondentID (from resp data vs state)', response.data.respondentID, '|', state.respondentID)
        console.log('state.uid=', state.uid)
        console.log('appID (from resp data vs state)', response.data.appID, '|', state.appID)
        console.log('data.realEnvID=', response.data.realEnvID)

        // Note: test on state.messages.length has been removed since, if there is no message stored locally
        // but respondent has been recognized by backend (throught his uid), this might mean that user has cleared his
        // his cache or connects via a different browser / device. This can only happen with uid query params set.
        if (
          (state.respondentID &&
            response.data.respondentID === state.respondentID) ||
          (state.uid &&
            response.data.respondentID === state.uid &&
            (response.data.appID === state.appID ||
              response.data.realEnvID === state.appID))
        ) {
          isSameUser = true;
        }

        commit('SAVE_APP_ID', response.data.realEnvID);
        // chatbotID will be needed when uploading file
        commit('SAVE_CHATBOT_ID', response.data.chatbotID);

        if (
          state.respondentID &&
          response.data.respondentID !== state.respondentID
        ) {
          // When '/resetinguser', socket must be disconnected before being connected again later
          console.log('User has changed, close socket');
          await fakeThis.$socket.client.close();
        }

        // Save the respondentID in the store anyway
        commit('SAVE_RESPONDENT', response.data.respondentID);
        // Json for the server to start the connection
        let json = {
          recipient: { id: state.appID },
          sender: { id: state.respondentID },
          message: { text: '_init_' },
          test_token: state.test_token,
          vz_access_token: state.access_token,
        };

        setupApplication(response.data.application, state, commit);

        if (isSameUser && !contentRequested) {
          // console.log('IS SAME USER and No content requested');
          commit('CHANGE_SHOW_CHAT', true);
          if (logged_in) {
            // for new loggedin people I need to restart the bot
            console.log('Just logged in!');
            json.message.text = '/reboot';
            if (state.ref) {
              console.log(
                '/reboot after loggin, state.ref are present',
                state.ref
              );
              json.referral = { ref: state.ref };
              // Forwarding ref data has to be done once only, so we erase them right after having forwarded them
              commit('SET_REF', undefined);
            }
            await dispatch('initSocketWithMessage', json);
            await dispatch('send_message', { payload: json });
          } else {
            if (response.data.mustLogin) {
              console.log('Respondent is known but must log in!');
              await displayFirstResourceContent(
                response.data,
                state,
                dispatch,
                commit
              );
              await dispatch('initSocketWithMessage', json);
            } else {
              // console.log('No need to log in')
              await dispatch('initSocketWithMessage', json);
              // Conversation is restarted here if no message can be found in local storage
              if (state.messages.length === 0) {
                console.log('>>>> Message list is empty ; reinitConversation');
                dispatch('autoReinitConversation');
              }
            }
          }
        } else {
          console.log('NOT SAME USER or Content requested');

          if (response.data.firstMessage) {
            console.log('INIT a new first message')
            const resource = response.data.firstMessage.currentResource;
            if (!contentRequested) {
              // Initial resource is the one returned during the initialization
              // of the widget and not when a specific content is requested.
              commit('SET_INITIAL_RESOURCE', resource);
            }

            commit('CHANGE_SHOW_CHAT', true);
            await dispatch('initForFirstMessage');
            await displayFirstResourceContent(
              response.data,
              state,
              dispatch,
              commit
            );
            // Device info is added to current ref data (to be saved in
            // respondent attributes)
            const { deviceInfoRef } = response.data;
            if (deviceInfoRef) {
              const newRef = state.ref
                ? `${state.ref},${deviceInfoRef}`
                : deviceInfoRef;
              commit('SET_REF', newRef);
            }

            if (contentRequested) {
              commit('SET_CURRENT_CONTEXT_RESOURCE_ID', resource._id)
            }
          }
        }

        resolve(isSameUser);
      })
      .catch((err) => {
        // This is where are processed errors coming from socketserver

        console.error(
          'Error when contacting socket server to initialize respondent',
          err
        );
        resolve(false);
      });
  });
}

export const initSocketWithMessage = ({ commit }, obj) => {
  // console.log('initSocketWithMessage', obj);
  commit('SET_TYPING_OFF');
  fakeThis.$socket.client.emit('initWithMessages', obj);
  // We put it there to avoid interference with 'checkSocket'
  commit('SET_SOCKET_IS_READY', true);
};

export const initForFirstMessage = ({ commit }) => {
  // console.log('initForFirstMessage for user obj', obj);
  commit('INIT_CONVERSATION');
  // On prévient le client que le serveur va répondre
  commit('SET_TYPING_ON');
};

export const send_notation_data = ({ dispatch }, data) => {
  dispatch('checkSocket');
  fakeThis.$socket.client.emit('notationData', data);
};

export const socket_message = ({ commit, state }, payload) => {
  console.log('socket_message', payload);
  // Continue only if the current app is the one targeted by socket message
  if (payload.appID !== state.appID) {
    return;
  }
  // console.log('Incoming "socket_message" message for this app! payload=', payload);

  if (!state.show_chat) {
    // This is the first message
    commit('CHANGE_SHOW_CHAT', true);
  }

  if (state.widget === 'closed') {
    // If widget is closed, forward message data to parent window so it can
    // display appropriate notification.
    let messageForParentWindow = {
      type: 'vizirMessageReceived',
      first_message: false,
      botMessage: payload.text,
      number: 1,
    };
    if (state.messages && state.messages.length === 0) {
      messageForParentWindow.first_message = true;
      messageForParentWindow.appInfo = payload.appInfo;
    }
    window.top.postMessage(JSON.stringify(messageForParentWindow), '*');
  }
  commit('USER_ACTIVE');
  commit('SET_TYPING_OFF');
  if (payload && payload.quick_replies && payload.quick_replies.length > 0) {
    commit('SHOW_QUICKREPLIES');
  }
  commit('SEND_MESSAGE', payload);
};

export const socket_typingoff = ({ commit, state }, payload) => {
  // console.log('socket_typingoff', payload);
  // Used only during livechat, while waiting for the human to send a message
  // Continue only if the current app is the one targeted by socket message
  if (payload.appID !== state.appID) {
    return;
  }
  // console.log('Incoming "socket_typingoff" message for this app! payload=', payload);

  commit('SET_TYPING_OFF');
};

export const socket_typingon = ({ commit, state }, payload) => {
  // console.log('socket_typingon', payload)
  // Continue only if the current app is the one targeted by socket message
  if (payload.appID !== state.appID) {
    return;
  }
  // console.log('Incoming "socket_typingon" message for this app! payload=', payload);

  commit('SET_TYPING_ON');
};


// Must use a normal functon iot access the Vue app via 'this'
export async function send_message(
  { commit, dispatch, state },
  { payload, userPayload, isVoice }
) {
  console.log('[Webchat] send_message', payload, userPayload)
  // console.log('respondentID', state.respondentID);

  const finalizedMessageData = await finalizeMessageData(
    { dispatch },
    { payload, userPayload }
  );
  payload = finalizedMessageData.payload;
  userPayload = finalizedMessageData.userPayload;

  // If it's the first message about to be sent, we need to tell socketserver to create a new respondent
  if (!state.respondentID) {
    await initBeforeSendingFirstMessage(
      state,
      payload,
      commit,
      dispatch,
      this._vm
    );
  } else {
    // Socket connection can be lost ; it must be verified before sending new message
    dispatch('checkSocket');
  }

  const respondentID = state.respondentID;
  const obj = {
    recipient: { id: state.appID },
    sender: { id: respondentID },
    test_token: state.test_token,
    resetUser: false,
    vz_access_token: state.access_token,
    ...payload,
  };
  // console.log(obj);
  // When sending message via widget methods, this is needed to keep mainworker
  // in sync with the current context of the message being sent.
  if (this.state.currentContextResourceID) {
    obj.referral = {
      ref: `lastResourceID:${state.currentContextResourceID}`,
    }
  }

  if (isVoice) {
    commit('SET_VOICE_STATUS_ACTIVE');
  } else {
    commit('SET_VOICE_STATUS_INACTIVE');
  }

  if (payload?.message?.text === '/resetuser') {
    console.log('message.text = /resetuser');
    commit('CHANGE_NAVIGATEUR', 0);
    commit('EMPTY_CONVERSATION');
    commit('REMOVE_ALL_MESSAGE_TO_READING_LIST');
    commit('SET_UID', undefined);

    let data = {
      appID: router.currentRoute.params.appID,
      respondentID: undefined,
      test_token: state.test_token,
      ref: router.currentRoute.query.ref,
    };
    // console.log(data);
    dispatch('isUserKnown', data);
  } else {
    commit('CHANGE_NAVIGATEUR', 0);

    // widgetParentUrl is retrieved through messaging between iframe and parent page
    if (state.isInIframe) {
      const eventData = { type: 'getWidgetParentUrl' };
      window.top.postMessage(JSON.stringify(eventData), '*');
      // This "pause" is necessary as postMessage is asynchronous and we must be sure that parent url forwarding
      // is complete before moving the next step
      await new Promise((resolve) => setTimeout(resolve, 50));
      obj.widgetParentUrl = state.widgetParentUrl;
    }

    // Current respondent might be a test respondent (eg, when widget is on
    // Vizir dashboard)
    // console.log('isTestUser=', state.isTestUser);
    if (state.isTestUser) obj.isTestUser = true

    if ((_.isEqual(Object.keys(payload), ['trigger']))) {
      // If only an event trigger is to be sent (ie, that does not represent an
      // answer to be interpreted by the bot)...
      fakeThis.$socket.client.emit('message', obj);
      // ... then do not do anything else.
      return
    }

    // This must be performed after "trigger only" block, to make sure
    // message is left untouched while "recording" user interaction details
    commit('HIDE_QUICKREPLIES');

    // console.log('Message object', obj);
    if (
      !_.isEmpty(userPayload) &&
      userPayload &&
      (userPayload.text === '/reboot_login' ||
        userPayload.text === '/first_login')
    ) {
      // commit('EMPTY_CONVERSATION')
      fakeThis.$socket.client.emit('message', obj);
    } else {
      commit('SEND_ANSWER', userPayload);
      fakeThis.$socket.client.emit('message', obj);
    }
    if (
      payload?.message?.quick_reply === 'clear' ||
      payload?.postback?.payload === 'clear'
    ) {
      commit('EMPTY_CONVERSATION');
    }

    commit('SET_TYPING_ON');
  }

  // Once the message has been sent, reset the current context of the conversation
  // (as mainworker is now aware of it and we do not want to make possible
  // interferences).
  commit('SET_CURRENT_CONTEXT_RESOURCE_ID', undefined)

}

export async function clear_conversation(
  { commit, dispatch, state },
  { payload }
) {
  // If it's the first message about to be sent, we need to tell socketserver to create a new respondent
  if (!state.respondentID) {
    await initBeforeSendingFirstMessage(
      state,
      payload,
      commit,
      dispatch,
      this._vm
    );
  } else {
    // Socket connection can be lost ; it must be verified before sending new message
    dispatch('checkSocket');
  }
  commit('HIDE_QUICKREPLIES');
  commit('CHANGE_NAVIGATEUR', 0);
  commit('EMPTY_CONVERSATION');
  commit('REMOVE_ALL_MESSAGE_TO_READING_LIST');
  var respondentID = state.respondentID;

  const obj = {
    recipient: { id: state.appID },
    sender: { id: respondentID },
    test_token: state.test_token,
    vz_access_token: state.access_token,
    resetUser: false,
    ...payload,
  };

  fakeThis.$socket.client.emit('message', obj);
}

export const updateFeedbackNLP = ({ dispatch }, payload) => {
  dispatch('checkSocket');
  fakeThis.$socket.client.emit('sendFeedbackNLP', payload);
};

export const updateMessagesWithFeedbackNLP = ({ commit }, data) => {
  commit('MESSAGE_WITH_FEEDBACK_NLP', data);
};

export const show_webview = ({ commit }, url) => {
  // console.log(url);
  commit('CHANGE_WEBVIEW_URL', url);
  commit('SHOW_WEBVIEW');
};

export const hide_webview = ({ commit }) => {
  commit('HIDE_WEBVIEW');
};

export const hide_informations = ({ commit }) => {
  commit('HIDE_INFORMATIONS');
};

export const show_informations = ({ commit, state }) => {
  if (state.showInformations === true) {
    commit('HIDE_INFORMATIONS');
  } else {
    commit('SHOW_INFORMATIONS');
  }
};

export const checkSocket = ({ commit, state }) => {
  console.log('Is socket ready?', state.socketIsReady);
  if (!state.socketIsReady) {
    fakeThis.$socket.client.emit('initWithMessages', {
      sender: { id: state.respondentID },
      recipient: { id: state.appID },
    });
    commit('SET_SOCKET_IS_READY', true);
  }
};

export const getSignedPutUrlForUpload = async ({ state }, fileName) => {
  // console.log('getSignedPutUrlForUpload')
  try {
    const res = await HTTP_SOCKET.get(
      `chatbots/${state.chatbotID}/upload/signed-put-url`,
      {
        params: { fileName },
      }
    );
    return res.data.signedPutUrl;
  } catch (error) {
    console.error('Error when trying to get signed-put-url:', error.toString());
    throw error;
  }
};

export const getSignedGetUrlForUpload = async ({ state }, fileUrl) => {
  // console.log('getSignedGetUrlForUpload')
  try {
    const res = await HTTP_SOCKET.get(
      `chatbots/${state.chatbotID}/upload/signed-get-url`,
      {
        params: { objectUrl: fileUrl },
      }
    );
    return res.data.signedGetUrl;
  } catch (error) {
    console.error('Error when trying to get signed-get-url:', error.toString());
    throw error;
  }
};

export const socket_add_operator = ({ commit }, payload) => {
  // console.log('socket_add_operator', payload)
  commit('ADD_AVAILABLE_OPERATOR', payload);
};

export const socket_remove_operator = ({ commit }, payload) => {
  // console.log('socket_remove_operator', payload)
  commit('REMOVE_AVAILABLE_OPERATOR', payload);
};

export const socket_update_operators = ({ commit }, payload) => {
  // console.log('socket_update_operators', payload)

  const addedOperators = payload.addedOperators || [];
  for (let addedOperator of addedOperators) {
    commit('UPDATE_AVAILABLE_OPERATOR', {
      operatorID: addedOperator.id,
      isAssigned: true,
    });
  }

  const removedOperators = payload.removedOperators || [];
  for (let removedOperator of removedOperators) {
    commit('UPDATE_AVAILABLE_OPERATOR', {
      operatorID: removedOperator.id,
      isAssigned: false,
    });
  }
};

export const autoReinitConversation = ({ state, commit, dispatch }) => {
  console.log('autoReinitConversation\nConversation will be reinitialized');
  const payload = {
    recipient: { id: state.appID },
    sender: { id: state.respondentID },
    // Periodic restarts must be distinguished from manual restarts
    // https://github.com/vizirco/dashboard/issues/1678
    message: { text: '/reboot_auto' },
    test_token: state.test_token,
    vz_access_token: state.access_token,
  };
  // According to Sacha we need to remove the historic conversation
  // when we perform an auto reboot: https://github.com/vizirco/dashboard/issues/3619
  commit('EMPTY_CONVERSATION');
  dispatch('send_message', { payload });
};

export const trackEvent = async ({ state }, payload) => {
  // When first click is performed, state.respondentID is not yet set
  const respondentID = state.respondentID || state.uid;
  console.log('trackEvent', payload, state.appID, state.respondentID)
  payload.source = 'web'
  if (respondentID) {
    payload.respondentID = respondentID;
  }
  if (respondentID?.includes('vizir_dashboard_test_respondent_')) {
    console.log('Event from "vizir_dashboard_test_respondent_"; ignore it!');
    return;
  }
  await HTTP_SOCKET.post(`/environments/${state.appID}/events`, payload)
};
