// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import * as Sentry from "@sentry/vue";

// used for copy in carousel url (not used for now)
import VueClipboard from 'vue-clipboard2';
import VueLazyload from 'vue-lazyload';
import VueTippy, { TippyComponent } from 'vue-tippy';
import socketio from 'socket.io-client';
import VueSocketIO from 'vue-socket.io-extended';

import App from './App';
// const App = () => import('./App')
import router from './router';
import store from './store';
import { mapActions } from 'vuex';
import { inIframe } from './common/dom';

const socketUrl = process.env.VUE_APP_socketserver;

// console.log(socketUrl)
const SocketInstance = socketio(
  socketUrl,
  // Socket will be connected later (after first message is sent by respondent)
  { autoConnect: false }
);

Vue.use(VueSocketIO, SocketInstance, store);
Vue.use(VueClipboard);
Vue.use(VueTippy);
Vue.use(VueLazyload);
// I need to make it false because production mode is not OK on IE11
Vue.config.productionTip = false;

Sentry.init({
  Vue,
  dsn: "https://4b7c0512fac54effabb6aae670069a26@o955899.ingest.sentry.io/4504208994992128",
})

Vue.component('Tippy', TippyComponent);

/* eslint-disable no-new */
new Vue({
  // el: '#app',
  router,
  store,
  components: { App },
  computed: {
    application() {
      return this.$store.getters.application;
    },
    respondentID() {
      return this.$store.getters.respondentID;
    },
    messages() {
      return this.$store.getters.messages;
    },
  },
  beforeCreate() {
    if (this.$route.params.appID) {
      // 15/12/2020: from now on, data are indexed by couple (appID, uid)
      this.$store.commit('LOAD_STATE', {
        urlAppID: this.$route.params.appID,
        uid: this.$route.query?.uid,
      });
      // console.log('Initial state =', this.$store.state)
      // console.log('state.respondentID', this.$store.state.respondentID)
    } else {
      console.error(
        'Application ID (appID) is not provided in url. Contact the person who provided you with this url.'
      );
    }
  },
  mounted() {
    // console.log('Vue appplication mounting')
    this.init();
  },
  updated() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been re-rendered
    });
  },
  methods: {
    ...mapActions(['isUserKnown']),
    async init() {
      // This is where the chat is set up depending on various conditions and input
      // console.log('[Webchat] Vue application "init()')
      // console.log('state = ', JSON.parse(JSON.stringify(this.$store.state)))

      // In any case, hide any remaining file preview
      this.$store.commit('CLOSE_FILE_PREVIEW');

      if (inIframe()) {
        // console.log('Widget mode (in iFrame)')
        this.$store.commit('SET_IN_IFRAME', true);
      } else {
        if (!(this.$route.query.logged_in === 'true')) {
          // Ensure that 'isInIframe' will be false if a bot in full page was previously used in iframe
          // This is done with previous test to make sure isInIframe is not modified during redirection to parent page
          // when in widget mode
          this.$store.commit('SET_IN_IFRAME', false);
        }
      }

      // init the chat is not open at the charge
      this.$store.commit('WIDGET_CLOSE');
      this.$store.commit('REMOVE_ALL_MESSAGE_TO_READING_LIST');
      this.$store.commit('SET_AVAILABLE_OPERATORS', []);
      let respondentID = this.respondentID;
      // https://github.com/vizirco/dashboard/issues/3475
      if (
        this.$route.query.uid &&
        this.$route.query.uid !== 'null' &&
        this.$route.query.uid !== 'undefined'
      ) {
        respondentID = this.$route.query.uid;
        // console.log('uid =', respondentID);
        // uid is retained so that it can be used to initialize respondent
        this.$store.commit('SET_UID', respondentID);
        if (
          respondentID.toString().startsWith('vizir_dashboard_test_respondent')
        ) {
          // If the respondent is interacting with widget on Vizir dashboard,
          // mark it as a test user
          this.$store.commit('SET_IS_TEST_USER', true);
        }
        // and so is appID (envID) in this case, as it will be checked when initializeRespondent returns
        this.$store.commit('SAVE_APP_ID', this.$route.params.appID);
      }
      if (this.$route.query.ref) {
        this.$store.commit('SET_REF', this.$route.query.ref);
      }

      if (this.$route.query.test && this.$route.query.test == 'true') {
        this.$store.commit('SET_TEST_TOKEN', this.$route.query.token);
      }

      let isSameUser;
      if (this.$route.query.logged_in === 'true') {
        // Redirected here after login
        console.log('logged_in in url')
        console.log('respondentID', respondentID)
        // console.log('this.$route.params.appID', this.$route.params.appID)
        this.$store.commit('SAVE_ACCESS_TOKEN', this.$route.query.access_token);
        this.$store.commit('SAVE_RESPONDENT', respondentID);
        this.$store.commit('SAVE_APP_ID', this.$route.params.appID);
        // console.log(this.$store.state.isInIframe)
        if (!this.$store.state.isInIframe) {
          // console.log('Not widget mode : everything can be done right now')
          let data = {
            appID: this.$store.state.appID,
            respondentID: this.$store.state.respondentID,
            ref: this.$route.query.ref,
            logged_in: true,
          };
          isSameUser = await this.isUserKnown(data);
        }
        else {
          // Widget mode : we gather all useful information for later
          // console.log('Logged in and in iFrame');

          // console.log('we redirect to the parent page we where at the beginning')
          // I don't want to use the redirect if I am on the callback page
          if (this.$route.name == 'Authcallback') {
            let data = {
              appID: this.$store.state.appID,
              respondentID: this.$store.state.respondentID,
              ref: this.$route.query.ref,
              logged_in: true,
            };
            isSameUser = await this.isUserKnown(data);
          } else {
            // console.log('Not in the')
            this.$store.commit('SET_LOGGED_IN', true);
            this.$store.commit('SET_REF', this.$route.query.ref);
            window.location.href = this.$store.state.widgetParentUrl;
          }
        }
      }
      else if (this.$store.state.isInIframe && this.$store.state.loggedIn) {
        // This is where we land, in widget mode, after having been redirected from login
        console.log('Widget mode: redirected from login');
        console.log('Second if');
        let data = {
          appID: this.$store.state.appID,
          respondentID: this.$store.state.respondentID,
          ref: this.$store.state.ref,
          logged_in: true,
        };
        // Get the respondentID and start the conversation
        isSameUser = await this.isUserKnown(data);
        window.top.postMessage(
          JSON.stringify({ type: 'widgetWantsToBeOpen' }),
          '*'
        );
        // Reset 'login' related information in order not to interfer with further reload of the page
        this.$store.commit('SET_LOGGED_IN', false);
        this.$store.commit('SET_WIDGET_PARENT_URL', undefined);
        this.$store.commit('SET_REF', undefined);
      } else {
        // console.log('Normal case');
        let data = {
          appID: this.$route.params.appID,
          respondentID: respondentID,
          ref: this.$route.query.ref,
        };
        // console.log(data);
        // Get the respondentID and start the conversation
        isSameUser = await this.isUserKnown(data);
      }
      if (
        !this.$store.state.isInIframe &&
        Boolean(Object.values(this.$route.query).length)
      ) {
        // In normal mode, clear the query if it exists and reload
        // In IFrame mode, I don't want to clear the query as it might have important information (sharepoint for exemple)
        console.log('About to reload', this.$route.path);
        if (this.$route.path !== '/authcallback') {
          this.$router
            .push({ path: this.$route.path, query: {} })
            .catch((err) => {
              console.log(err.message);
            });
        }
      }
      // console.log('End of the functino with isSameUser = ', isSameUser)
      if (isSameUser) {
        // If respondent is known, socket can be open right now
        // console.log('Normal case; about to open socket')
        this.$socket.client.open();
      }

      window.top.postMessage(
        JSON.stringify({ type: 'vizirWidgetSendEvent', event: 'ready' }),
        '*'
      );
    },
  },
  template: '<App/>',
  sockets: {
    connect() {
      // TODO: Investigate in what cases this is really necessary
      // For now, it seems to not have any use and send a double call to the API
      // console.log('Socket is connected')
      if (this.$store.state.respondentID) {
        // console.log('...and respondentID is defined, so init socket.')
        const obj = {
          recipient: { id: this.$store.state.appID },
          sender: { id: this.$store.state.respondentID },
        };
        this.$socket.client.emit('initWithMessages', obj);
      } else {
        console.log('RespondentID is not defined, so do nothing for now.');
      }
    },
    disconnect() {
      // console.log('Socket disconnected')
      this.$store.commit('SET_SOCKET_IS_READY', false);
    },
    message(val) {
      // console.log('socket message received', val);
      this.$store.dispatch('socket_message', val);
    },
    typingoff(val) {
      //console.log('typingoff', val)
      this.$store.dispatch('socket_typingoff', val);
    },
    typingon(val) {
      this.$store.dispatch('socket_typingon', val);
    },
    addOperator(obj) {
      this.$store.dispatch('socket_add_operator', obj);
    },
    removeOperator(obj) {
      this.$store.dispatch('socket_remove_operator', obj);
    },
    updateOperators(obj) {
      this.$store.dispatch('socket_update_operators', obj);
    },
    setOperators(payload) {
      this.$store.commit('SET_AVAILABLE_OPERATORS', payload);
    },
    updateLiveStatus(payload) {
      this.$store.commit('CHANGE_LIVE_STATUS', payload.liveStatus);
    },
  },
  render: (createEle) => createEle(App),
}).$mount('#app');
